@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
    font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Prevent wheel scroll when typing numbers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  /* Disable arrow key navigation */
  cursor: text;

}
